.default-var, .device-var{
  color: #2B334A;
  padding: 7px;
  cursor: pointer;
}
.default-var {
  background: #E9EDFC;
}
.device-var {
  background: rgba(172,211,86,0.25);
}