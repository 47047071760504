.inline-tools {
  display: flex;
  margin-top:10px;
  margin-bottom: 10px;
  .controls{
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    width: 75%;
  }
  .tool-label {
    text-transform: uppercase;
    font-size: 12px;
    color:#6C7A88;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
}
.widget-content, .editor-parametrized-text{
  h2 {
    color: #0C2340;
    font-size: 26px;
    font-weight: bold;
  }
  h3{
    color: #0C2340;
    font-size: 24px;
    font-weight: bold;
  }
  h6{
    font-size: 20px;
  }
  div {
    font-size: 16px;
  }
}
.editor-parametrized-text{
  width: 100%;
  overflow: hidden;
  .DraftEditor-root{
    border-bottom: 2px dashed #8AC3FA;
  }
  &.static{
    .DraftEditor-root{
      border-bottom: 0;
    } 
  }
}
.alignment-button{
   background: none;
   border: none;
   outline: none;
   font-size: 20px;
   padding: 10px;
   color: #2B334A;
   height: 40px;
   width: 40px;
   position: relative;
   i{
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);
   }
   &.active{
     color: #577EE8;
     border-radius: 50%;
     background: rgba(46,91,255,0.08);
   }
   &:focus{
     outline: none;
   }
}
.block-style{
  background: none;
  outline: 0;
  border: 0;
  margin: 7px;
  &.isActive{
    color: #577EE8
  }
  &:focus{
    outline: 0;
  }
}
.close-btn {
  color:#6C7A88;
  font-size: 12px;
  cursor: pointer;
}
.suggestions-add-wrapper{
  max-width: 100%;
  flex-wrap: wrap;
}
.dark {
  h2 {
    color: #FFFFFF;
    font-size: 26px;
    font-weight: bold;
  }
  h3{
    color: #FFFFFF;
    font-size: 24px;
    font-weight: bold;
  }
  h6{
    color: #FFFFFF;
    font-size: 20px;
  }
  div {
    color: #FFFFFF;
    font-size: 16px;
  }
  span {
    color: #FFFFFF;
  }
  i {
    color: #FFFFFF !important;
  }
}

.darkTools {
  h2 {
    color: #2B334A;
    font-size: 26px;
    font-weight: bold;
  }
  h3{
    color: #2B334A;
    font-size: 24px;
    font-weight: bold;
  }
  h6{
    color: #2B334A;
    font-size: 20px;
  }
  div {
    color: #2B334A;
    font-size: 16px;
  }
  span {
    color: #9DA7B0;
  }
  .default-var, .device-var {
      color: #2B334A!important;
  }
 
  i {
    color: #2B334A !important;
  }
  button.active { 
    i {
      color: #577EE8 !important;
    }
  }
}