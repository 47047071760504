@import "../../configuration/sass/colors";
@import "../../configuration/sass/fonts";

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .button {
        margin: 0 15px;
        width: 100%;
        max-width: 70px;
        .switchButton {
            position: relative;
            display: inline-block;
            min-height: 40px;
            width: 100%;
            padding: 4px;
          }
          
          .switchButton input { 
            opacity: 0;
            width: 0;
            height: 0;
          }
          
          .circleButton {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $ui-Grey3;
            -webkit-transition: .2s;
            transition: .2s;
          }
          
          .circleButton:before {
            position: absolute;
            content: "";
            left: 4px;
            width: 30px;
            height: 30px;
            bottom: 5px;
            background-color: $ui-White;
            -webkit-transition: .2s;
            transition: .2s;
          }
          
      

          input:checked + .circleButton.round:before {
            -webkit-transform: translateX(100%);
            -ms-transform: translateX(100%);
            transform: translateX(100%);
          }
        
          
          .circleButton.round {
            border-radius: 31px;
          }
          
          .circleButton.round:before {
            border-radius: 50%;
          }
    }

    .stateOff {
        color: $ui-Grey1;
        font-family: $typography;
        font-size: 1em;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
    }
    .stateOn {
        color: $ui-Black;
        font-family: $typography;
        font-size: 1em;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 17px;
    }
    
}