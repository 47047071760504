@import "../WidgetTable/styles.scss";
@import "../../configuration/sass/mixins";
@import "../../configuration/sass/colors";

.ag-theme-alpine {

  .ag-center-cols-container {
    div {
      text-align: left;
    }
  }

  .ag-cell-value{

    .low {
      @include severity-box();
      background: $severity-low;
      
    }
    .high {
      @include severity-box();
      background: $severity-critical;
    }

    .medium {
      @include severity-box();
      background: $severity-high;
    }

    .issueSeverity {
      i {
          font-size: 150%;
          margin-left: 1.25rem;
          color: $ui-Grey1;
      }
    }

    .issueAttachment {
      color: $ui-primary !important;
      font-weight: 600;
      font-style: normal;
      display: flex;
      align-items: center;
    }
    
    .issueAttachment::before {
      font-size: 1.4rem !important;
    }
  }
  
}

div.SwitchModeProfile {
  display: inline-flex;
}

.SwitchModeSelector{

  display: flex;
  align-items: center;
  justify-content: left;

  .SwitchModeOption{
      margin-right: 1rem;
      margin-bottom: 0.8rem;
      margin-top: 0;
      font-family: "Source Sans Pro";
      font-size: 0.8rem;
      font-weight: 600;
      letter-spacing: -0.1;
      line-height: 1rem;
      background-color: #F8F8F8;
      text-align: center;
      width: 5.5rem;
      height: 2rem;
      border-width: 0;
      border-style: none;
      border-image: none;
      border-radius: 0.4rem;
      border-color: transparent;
      outline-style: none;
      color: #909090;
      }

  .SwitchModeOption.active{
      margin-right: 1rem;
      margin-bottom: 0.8rem;
      margin-top: 0;
      background-color: #577EE8;
      box-shadow: 0 0.2rem 1.25rem 0 rgba(31,142,250,0.56);
      color: #F9FAFC;
      margin-right: 1.5em;
      margin-bottom: 1.5em;
      font-family: "Source Sans Pro";
      font-size: 0.813rem;
      font-weight: 600;
      letter-spacing: -0.1;
      line-height: 1rem;
      text-align: center;
      width: 5.5rem;
      height: 2rem;
      border-width: 0;
      border-style: none;
      border-image: none;
      border-radius: 0.4rem;
      border-color: transparent;
      outline-style: none;
  }
}

.customFilter {
  width: 200px;
  height: 200px;
  .selectCustomFilter {
    .css-26l3qy-menu {
      display: block;
      height: 200px;
      overflow: auto;
    }
  }
}