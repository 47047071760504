@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600&display=swap');

$typography: 'Source Sans Pro', sans-serif;
$secondaryTypography: 'Source Sans Pro', sans-serif;

$baseFontSize: 15px;

@media (min-width: 1850px){
    $baseFontSize: $baseFontSize * 2;
}
